import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useInView } from 'react-intersection-observer';
import styles from './PromoMessage.scss';
import Text from '../../../Text';
import { DESKTOP, MOBILE } from '../../../constants';
import TimeRemaining from '../../../TimeRemaining';

const SITEWIDE_BANNER = 'sitewide_banner';

const PromoMessage = ({ banners, profile }) => {
  const [inViewRef, inView] = useInView({ triggerOnce: true });
  const containerRef = useRef(null);
  const isDesktop = profile === DESKTOP;
  const [banner] = banners || [];
  let bannerMessage = banner && banner.message;

  const combinedRefs = useCallback((node) => {
    containerRef.current = node;
    inViewRef(node);
  }, [inView]);

  React.useEffect(() => {
    if (inView && Array.isArray(banners) && banners.length) {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        event: 'view_promotion',
        page_location: window.location.href,
        ecommerce: {
          promotion_name: bannerMessage,
          creative_slot: SITEWIDE_BANNER,
        },
      });
    }
  }, [inView]);

  const logEvent = () => {
    try {
      window.dataLayer.push({
        event: 'select_promotion',
        page_location: window.location.href,
        ecommerce: {
          promotion_name: bannerMessage,
          creative_slot: SITEWIDE_BANNER,
        },
      });
    } catch (err) {
      console.error('There was error reporting select_promotion event', err);
    }
  };

  React.useEffect(() => {
    const link = containerRef.current ? containerRef.current.querySelector('a') : null;

    if (bannerMessage && link) {
      link.addEventListener('click', logEvent);
    }

    return () => {
      if (bannerMessage && link) {
        link.removeEventListener('click', logEvent);
      }
    };
  }, [banners]);

  if (!(Array.isArray(banners) && banners.length)) {
    return (
      <div className={cnames(
        styles.promoMessageContainer,
        {
          [styles.desktop]: isDesktop,
        },
      )}
      />
    );
  }

  const classNames = cnames(styles.promoMessage, {
    [styles.desktop]: isDesktop,
  });

  const showCountdown = bannerMessage && bannerMessage.includes('[c]');
  bannerMessage = bannerMessage && bannerMessage.replace('[c]', '').trim();

  return bannerMessage ? (
    <div className={classNames} data-testid="ds-promo-message" ref={combinedRefs}>
      <TimeRemaining until={banner.endDate}>
        {
          (timeRemaining) => {
            const outOfTime = timeRemaining && timeRemaining === '00:00:00';
            if (outOfTime) return null;

            const messageClassNames = cnames(styles.message, {
              [styles.messageOnly]: !timeRemaining || !showCountdown,
            });

            return (
              <div className={styles.wrapper}>
                {
                  isDesktop && timeRemaining && showCountdown && (
                    <div className={styles.timeRemaining}>
                      <Text
                        display="block"
                        type="caption"
                        className={styles.ends}
                      >
                        <FormattedMessage defaultMessage="Offer ends in" />
                      </Text>
                      <Text display="block" type="display5">
                        {timeRemaining}
                      </Text>
                    </div>
                  )
                }
                <Text type={isDesktop ? 'body' : 'body2'} className={messageClassNames}>
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: bannerMessage }}
                  />

                  {
                    !isDesktop && timeRemaining && showCountdown && (
                      <React.Fragment>
                        {' '}
                        <Text
                          type="body2"
                          className={styles.ends}
                        >
                          <FormattedMessage defaultMessage="Offer ends in" />
                        </Text>
                        <Text type="body2">
                          <strong>
                            {timeRemaining}
                          </strong>
                        </Text>
                      </React.Fragment>
                    )
                  }
                </Text>
              </div>
            );
          }
        }
      </TimeRemaining>
    </div>
  ) : null;
};

PromoMessage.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
  })),
  profile: PropTypes.oneOf([DESKTOP, MOBILE]),
};

PromoMessage.defaultProps = {
  banners: [],
  profile: DESKTOP,
};

export default PromoMessage;
