import React, { Component, useContext } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import cnames from 'classnames';
import DesignSystemContext from '../../../contexts/DesignSystemContext';
import {
  CREATIVE_ADVENTURES_DARK,
  FIND_YOUR_THING_DARK,
} from '../../../themes/ids';
import Drawer from '../../../Drawer';
import Button from '../../../Button';
import Tooltip from '../../../Tooltip';
import Logo from '../../../Logo';
import Box from '../../../Box';
import PageSection from '../../../PageSection';
import HeartLargeIcon from '../../../Icons/HeartLarge';
import PromoMessage from '../PromoMessage';
import HeaderLinks from '../HeaderLinks';
import ArtistMetrics from '../ArtistMetrics';
import UserMenu from '../UserMenu';
import CartCountBadge from '../CartCountBadge';
import { MobileProductNav, StaticMobileProductNav, itemsPropShape } from '../../../ProductNav';
import * as constants from '../../../constants';
import Modal from '../../../Modal';
import CartIcon from '../../../Icons/Cart';
import HamburgerIcon from '../../../Icons/Hamburger';
import SearchIcon from '../../../Icons/Search';
import TypeaheadMobile from '../Typeahead/components/mobile';
import TypeaheadDesktop from '../Typeahead/components/desktop';
import SearchBarWithTypeahead from '../Typeahead/components/mobile/SearchBarWithTypeahead';
import { doSearch } from '../../lib/doSearch';
import styles from './style.scss';
import setupScrollDirectionListener from '../../../utils/setupScrollDirectionListener';
import { SCROLL_DIRECTION } from '../../../utils/setupScrollDirectionListener/constants';
import withNextAction from '../../../utils/withNextAction';

const messages = defineMessages({
  viewCart: 'View Cart',
  cartLabel: 'Cart',
  loginSignup: 'Log In or Sign Up',
  lists: 'Your Lists',
  listsLabel: 'Lists',
  accessibleTitle: 'Products',
  searchButtonLabel: 'Search',
  searchLabel: 'Search',
  sellArt: 'Sell your art',
  login: 'Login',
  signup: 'Signup',
});

const LogoWrapper = ({ bauble }) => {
  const { theme } = useContext(DesignSystemContext);
  const isDarkTheme = theme.id === CREATIVE_ADVENTURES_DARK || theme.id === FIND_YOUR_THING_DARK;

  return (
    <Logo colored={!isDarkTheme} contrast={isDarkTheme} bauble={bauble} />
  );
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isServer: true,
      isSearchBoxOpen: false,
      scrollDirection: undefined,
    };

    this.onCartClick = this.onCartClick.bind(this);
    this.openSearchBox = this.openSearchBox.bind(this);
    this.closeSearchBox = this.closeSearchBox.bind(this);
    this.handleLegacyGoToFavoritesClick = this.handleLegacyGoToFavoritesClick.bind(this);
    this.updateScrollDirection = this.updateScrollDirection.bind(this);
  }

  onCartClick(e) {
    const { logEvent, onCartClick } = this.props;

    if (onCartClick && typeof onCartClick === 'function') onCartClick(e);

    const analytics = {
      category: 'Go To Cart',
      action: 'Click',
      label: 'header',
    };
    logEvent({ analytics });
  }

  // DEPRECATED, pass through `onHeartClick` instead
  handleLegacyGoToFavoritesClick(e) {
    const { goToFavorites } = this.props;
    if (typeof goToFavorites !== 'function') {
      return;
    }

    e.preventDefault();
    goToFavorites();
  }

  openSearchBox() {
    this.setState({ isSearchBoxOpen: true });
  }

  closeSearchBox() {
    this.setState({ isSearchBoxOpen: false });
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      isServer: false,
      scrollDirection: SCROLL_DIRECTION.NONE,
    });

    const { position } = this.props;
    if (position === 'fixed') {
      this.scrollDirectionCleanup = setupScrollDirectionListener(this.updateScrollDirection);
    }
  }

  componentWillUnmount() {
    const { position } = this.props;
    if (position === 'fixed') {
      this.scrollDirectionCleanup();
    }
  }

  updateScrollDirection(scrollDirection) {
    if (this.state.scrollDirection !== scrollDirection) {
      this.setState({ scrollDirection });
    }
  }

  render() {
    const {
      intl,
      profile,
      userInfo,
      userAccount,
      cart,
      searchTerm,
      doLogin,
      doSignup,
      baseUrl,
      globalNavigation,
      searchProductContext,
      onHeartClick,
      goToFavorites,
      logEvent,
      typeaheadDisableEventLogging,
      disableSearch,
      useRainbowStyle,
      engagingPlaceholderMessages,
      showExpandedMobileSearch,
      position,
      showBaubleLogo,
      onTypeaheadResultClick,
      onSearchCallback,
      onNavItemClick,
      onNavItemView,
    } = this.props;
    const {
      isServer,
      isSearchBoxOpen,
      scrollDirection,
    } = this.state;

    const { isLoggedIn, locale, avatar, username } = userInfo || {};
    const { metrics, isArtist, onboardedAt } = userAccount || {};
    const { itemsCount } = cart || {};
    const baseUrlWithLocale = locale === 'en' ? baseUrl : `${baseUrl}/${locale || 'en'}`;
    const cartItemsCount = itemsCount || 0;

    const desktop = profile === constants.DESKTOP;

    const heartLink = isLoggedIn
      ? `${baseUrlWithLocale}/lists?ref=header`
      : `${baseUrlWithLocale}/signup?ref=header_heart`;

    const handleHeartClick = (ev) => {
      if (goToFavorites && !onHeartClick) {
        return this.handleLegacyGoToFavoritesClick(ev);
      }

      if (!goToFavorites && onHeartClick) {
        return onHeartClick(ev);
      }

      if (!isLoggedIn) {
        window.location.href = withNextAction(heartLink);
        return ev.preventDefault();
      }

      // no default action
      return null;
    };

    const typeaheadSearch = (term, productContext = {}) => {
      doSearch(
        term,
        productContext,
        baseUrlWithLocale,
        this.props.doSearch,
      );
    };

    const calculateHeaderHeight = () => {
      if (desktop) {
        return constants.DESKTOP_HEADER_HEIGHT;
      }

      if (!desktop && showExpandedMobileSearch) {
        return constants.MOBILE_EXPANDED_HEADER_HEIGHT;
      }

      return constants.MOBILE_HEADER_HEIGHT;
    };

    return (
      <>
        <Box
          className={cnames(styles.headerWrapper,
            { [styles.fixed]: position === 'fixed',
              [styles.transformed]: showExpandedMobileSearch && profile === constants.MOBILE && scrollDirection === 'down' })}
          data-testid="ds-header"
        >
          <Box>
            <PageSection
              backgroundColor="var(--ds-color-background-ui)"
            >
              <Box
                element="header"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={desktop ? 1 : 0.5}
                paddingRight={desktop ? 2 : 0.5}
                paddingLeft={desktop ? 2 : 0.5}
                className={styles.header}
                flexWrap={showExpandedMobileSearch ? 'wrap' : 'nowrap'}
              >
                {
                  !desktop ? (
                    <>
                      <Drawer
                        accessibleTitle={intl.formatMessage(messages.accessibleTitle)}
                        trigger={({ onClick }) => (
                          <Button
                            circle
                            onClick={onClick}
                            aria-label={intl.formatMessage(messages.accessibleTitle)}
                            iconBefore={<HamburgerIcon />}
                            data-testid="ds-header-hamburger-action"
                          />
                        )}
                        from={constants.LEFT}
                        fit
                      >
                        <MobileProductNav
                          intl={intl}
                          userInfo={userInfo}
                          userAccount={userAccount}
                          doLogin={doLogin}
                          doSignup={doSignup}
                          baseUrl={baseUrlWithLocale}
                          items={globalNavigation}
                          logEvent={logEvent}
                          onNavItemClick={onNavItemClick}
                          onNavItemView={onNavItemView}
                        />
                      </Drawer>
                      {
                        disableSearch && (
                          <Box aria-hidden className={styles.hidden}>
                            <Button circle disabled />
                          </Box>
                        )
                      }

                      {
                        !disableSearch && !showExpandedMobileSearch && (
                          <Box>
                            <Modal
                              accessibleTitle={intl.formatMessage(messages.searchLabel)}
                              hideCloseButton
                              trigger={({ onClick }) => (
                                <Button
                                  circle
                                  iconBefore={<SearchIcon size={constants.MEDIUM} />}
                                  aria-label={intl.formatMessage(messages.searchButtonLabel)}
                                  onClick={(e) => {
                                    e.target.blur();
                                    onClick(e);
                                  }}
                                />
                              )}
                            >
                              {
                                ({ close }) => (
                                  <TypeaheadMobile
                                    locale={locale}
                                    logEvent={logEvent}
                                    baseUrl={baseUrl}
                                    intl={intl}
                                    onSearchClose={close}
                                    term={searchTerm}
                                    productContextIaCode={get(searchProductContext, 'id', null)}
                                    productContextLabel={get(searchProductContext, 'label', null)}
                                    doSearch={typeaheadSearch}
                                    disableEventLogging={typeaheadDisableEventLogging}
                                    onTypeaheadResultClick={onTypeaheadResultClick}
                                    onSearchCallback={onSearchCallback}
                                  />
                                )
                              }
                            </Modal>
                          </Box>
                        )
                      }
                    </>
                  ) : (
                    <Box
                      flex="1"
                      marginRight="m"
                      display="flex"
                      alignItems="center"
                    >
                      <HeaderLinks
                        isDesktop={desktop}
                        isLoggedIn={isLoggedIn}
                        baseUrl={baseUrlWithLocale}
                        intl={intl}
                        doLogin={doLogin}
                        doSignup={doSignup}
                      />
                      {
                        !disableSearch && (
                          <Box flex="1" marginRight="l" style={{ order: -1 }}>
                            <TypeaheadDesktop
                              locale={locale}
                              baseUrl={baseUrlWithLocale}
                              intl={intl}
                              logEvent={logEvent}
                              term={searchTerm}
                              useRainbowStyle={useRainbowStyle}
                              doSearch={typeaheadSearch}
                              productContextIaCode={get(searchProductContext, 'id', null)}
                              productContextLabel={get(searchProductContext, 'label', null)}
                              onSearchOpen={this.openSearchBox}
                              onSearchClose={this.closeSearchBox}
                              disableEventLogging={typeaheadDisableEventLogging}
                              engagingPlaceholderMessages={engagingPlaceholderMessages}
                              onTypeaheadResultClick={onTypeaheadResultClick}
                              onSearchCallback={onSearchCallback}
                            />
                          </Box>
                        )
                      }
                    </Box>
                  )
                }
                <Box
                  alignItems="center"
                  display="flex"
                  flex={!desktop ? '1' : ''}
                  justifyContent={showExpandedMobileSearch ? 'left' : 'center'}
                  marginLeft={showExpandedMobileSearch ? 'xs' : 'none'}
                  marginRight={desktop ? 1.25 : 0}
                  style={desktop ? { order: '-1' } : {}}
                >
                  <a
                    style={{ width: desktop ? '180px' : '133px', display: 'block' }}
                    href={`${baseUrlWithLocale}/`}
                  >
                    <LogoWrapper bauble={showBaubleLogo} />
                  </a>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                >
                  {
                    (desktop && !!isLoggedIn) && (
                      <Box marginRight="xxs">
                        <UserMenu
                          profile={profile}
                          intl={intl}
                          baseUrl={baseUrlWithLocale}
                          avatar={avatar}
                          username={username}
                          isArtist={isArtist}
                          locale={locale}
                          onboardedAt={onboardedAt}
                        />
                      </Box>
                    )
                  }
                  {
                    (desktop && !!metrics) && (
                      <Box marginRight="xxs">
                        <ArtistMetrics
                          baseUrl={baseUrlWithLocale}
                          intl={intl}
                          {...metrics}
                        />
                      </Box>
                    )
                  }
                  <Box marginRight={desktop ? 0.25 : 0}>
                    <Tooltip
                      trigger={triggerProps => (
                        <Button
                          circle
                          size={desktop ? constants.LARGE : constants.MEDIUM}
                          href={heartLink}
                          onClick={handleHeartClick}
                          title={intl.formatMessage(messages.lists)}
                          rel="nofollow"
                          data-testid="ds-header-lists-link"
                          {...triggerProps}
                        >
                          <HeartLargeIcon />
                        </Button>
                      )}
                      accessibleTitle={intl.formatMessage(messages.lists)}
                    >
                      {intl.formatMessage(messages.listsLabel)}
                    </Tooltip>
                  </Box>
                  <Box style={{ position: 'relative' }}>
                    <Tooltip
                      trigger={triggerProps => (
                        <>
                          {
                            (!isServer && !!cartItemsCount) && (
                              <CartCountBadge
                                count={cartItemsCount}
                              />
                            )
                          }

                          <Button
                            circle
                            size={desktop ? constants.LARGE : constants.MEDIUM}
                            onClick={this.onCartClick}
                            rel="nofollow"
                            href={`${baseUrlWithLocale}/cart`}
                            title={intl.formatMessage(messages.viewCart)}
                            {...triggerProps}
                            data-testid="ds-header-cart-link"
                          >
                            <CartIcon />
                          </Button>
                        </>
                      )}
                      accessibleTitle={intl.formatMessage(messages.viewCart)}
                    >
                      {intl.formatMessage(messages.cartLabel)}
                    </Tooltip>
                  </Box>
                </Box>

                {!disableSearch && showExpandedMobileSearch && (
                  <Box flex="1 1 100%" paddingBottom="xxs" paddingTop="s">
                    <SearchBarWithTypeahead
                      locale={locale}
                      baseUrl={baseUrlWithLocale}
                      intl={intl}
                      logEvent={logEvent}
                      term={searchTerm}
                      doSearch={typeaheadSearch}
                      onSearchOpen={this.openSearchBox}
                      onSearchClose={this.closeSearchBox}
                      disableEventLogging={typeaheadDisableEventLogging}
                      searchProductContext={searchProductContext}
                      engagingPlaceholderMessages={engagingPlaceholderMessages}
                      useRainbowStyle={useRainbowStyle}
                      onTypeaheadResultClick={onTypeaheadResultClick}
                      onSearchCallback={onSearchCallback}
                    />
                  </Box>
                )}
              </Box>
            </PageSection>
          </Box>
          {
            !desktop && (
              <StaticMobileProductNav
                items={globalNavigation}
                intl={intl}
                userInfo={userInfo}
                logEvent={logEvent}
              />
            )
          }
        </Box>

        {/* fixed header placeholder */}
        {position === 'fixed'
            && <Box style={{ height: calculateHeaderHeight(), width: '100%' }} data-testid="ds-header-placeholder" />
          }

        {isSearchBoxOpen && <Box className={styles.searchOpen} data-testid="typeaheadOverlay" />}
      </>
    );
  }
}


Header.displayName = 'Header';

Header.propTypes = {
  // eslint-disable-next-line react/no-typos
  intl: PropTypes.shape({}).isRequired,
  profile: PropTypes.oneOf([constants.DESKTOP, constants.MOBILE]),
  banners: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
  })),
  userInfo: PropTypes.shape({
    avatar: PropTypes.string,
    username: PropTypes.string,
    locale: PropTypes.string,
    country: PropTypes.string,
    isLoggedIn: PropTypes.bool,
  }),
  userAccount: PropTypes.shape({
    isArtist: PropTypes.bool,
  }),
  cart: PropTypes.shape({
    id: PropTypes.string,
    busy: PropTypes.bool,
    itemsCount: PropTypes.number,
  }),
  searchTerm: PropTypes.string,
  doSearch: PropTypes.func,
  doLogin: PropTypes.func,
  doSignup: PropTypes.func,
  onHeartClick: PropTypes.func,
  logEvent: PropTypes.func,
  baseUrl: PropTypes.string,
  globalNavigation: itemsPropShape.isRequired,
  searchProductContext: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
  typeaheadDisableEventLogging: PropTypes.bool,
  onCartClick: PropTypes.func,
  disableSearch: PropTypes.bool,
  /**
   * If true, the rainbow border style will be applied to the search box
   */
  useRainbowStyle: PropTypes.bool,
  /**
   * Pass an array of strings containing 2 or more strings to provide
   * an alternate placeholder text for the search bar.
   *
   * If the value is not a valid array, (null, undefined, less than 2 strings),
   * the default placeholder text experience will be used.
   * Exception: when reduce motion setting is enabled on user's device,
   * the default placeholder text will be shown instead.
  */
  engagingPlaceholderMessages: PropTypes.arrayOf(PropTypes.string),
  /**
   * If true, search box will appear on a separate line within the header
   */
  showExpandedMobileSearch: PropTypes.bool,
  /**
   * If fixed, search bar will be fixed to the top when scrolling
   */
  position: PropTypes.oneOf(['relative', 'fixed']),
  showBaubleLogo: PropTypes.bool,
  /**
   * Optional callback for clicking a typeahead result event
   * Provides a string of the typeahead item type
   */
  onTypeaheadResultClick: PropTypes.func,
  /**
   * Optional callback for when a user searches using typeahead
   */
  onSearchCallback: PropTypes.func,
  /**
   * Optional callback for when a user clicks a nav item link
   */
  onNavItemClick: PropTypes.func,
  /**
   * Optional callback for when a user opens a nav submenu
   */
  onNavItemView: PropTypes.func,
};

Header.defaultProps = {
  profile: constants.DESKTOP,
  banners: [],
  userInfo: {},
  userAccount: null,
  cart: {},
  searchTerm: '',
  logEvent: () => { },
  doSearch: null,
  doLogin: null,
  doSignup: null,
  onHeartClick: null,
  useRainbowStyle: false,
  baseUrl: '',
  searchProductContext: null,
  typeaheadDisableEventLogging: true,
  onCartClick: null,
  disableSearch: false,
  engagingPlaceholderMessages: null,
  showExpandedMobileSearch: false,
  position: 'relative',
  showBaubleLogo: false,
};

export { PromoMessage };
export default Header;
