// Profiles
export const MOBILE = 'mobile';
export const DESKTOP = 'desktop';

// Light / Dark
export const LIGHT = 'light';
export const DARK = 'dark';

// Sizes
export const TINY = 'tiny';
export const XSMALL = 'x-small';
export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const XLARGE = 'x-large';
// Deprecated
export const EXTRA_LARGE = 'extraLarge';
export const JUMBO = 'jumbo';
export const AUTO = 'auto';

// Intents
export const DEFAULT = 'default';
export const PRIMARY = 'primary';
export const EXPRESSIVE = 'expressive';
export const ERROR = 'error';
export const DESTRUCTIVE = ERROR;
export const SUCCESS = 'success';
export const INFORMATION = 'information';
export const WARNING = 'warning';
export const NEUTRAL = 'neutral';
export const TOGGLE = 'toggle';
export const SUBTLE = 'subtle';
export const INVERSE = 'inverse';
export const EXPRESSIVE_TEAL = 'expressiveTeal';

// Elevations
export const ELEVATION_HIGH = 'elevation-high';
export const ELEVATION_MEDIUM = 'elevation-medium';
export const ELEVATION_LOW = 'elevation-low';

// Color
export const COLOR_WHITE = 'var(--ds-color-white)';
export const COLOR_NEUTRAL_100 = 'var(--ds-color-neutral-100)';
export const COLOR_NEUTRAL_200 = 'var(--ds-color-neutral-200)';
export const COLOR_NEUTRAL_500 = 'var(--ds-color-neutral-500)';
export const COLOR_SECONDARY = 'var(--ds-color-neutral-800)';

// ratio
export const RATIO_SQUARE = 'square';
export const RATIO_PORTRAIT = 'portrait';
export const RATIO_LANDSCAPE = 'landscape';
export const RATIO_COVER = 'cover';
export const RATIO_CONTAIN = 'contain';

// alignments and positioning
export const TOP = 'top';
export const RIGHT = 'right';
export const BOTTOM = 'bottom';
export const LEFT = 'left';
export const CENTER = 'center';
export const TOP_LEFT = 'top-left';
export const TOP_RIGHT = 'top-right';
export const BOTTOM_LEFT = 'bottom-left';
export const BOTTOM_RIGHT = 'bottom-right';

// Keyboard codes
export const KEY_ENTER = 13;
export const KEY_SPACE = 32;
export const KEY_ESC = 27;

// Color Formats
export const FORMAT_HEX = 'hex';
export const FORMAT_RGB = 'rgb';

// Direction
export const HORIZONTAL = 'horizontal';
export const VERTICAL = 'vertical';

// Element height
export const DESKTOP_HEADER_HEIGHT = '88px';
export const MOBILE_EXPANDED_HEADER_HEIGHT = '120px';
export const MOBILE_HEADER_HEIGHT = '56px';
export const DESKTOP_NAV_HEIGHT = '54px';

// Typeahead result type constants
export { RECENT,
  POPULAR,
  TRENDING,
  CATEGORIES,
  ARTISTS,
  FALLBACK } from './headerAndFooter/components/Typeahead/constants';
