import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cnames from 'classnames';
import { isNumber } from 'lodash';
import styles from './EngagingSearchPlaceholder.scss';

// time in milliseconds for the duration each message should be shown for
// this is here because we want to avoid different consumers of DS
// from overriding this value and providing an inconsistent experience
const ANIMATION_DELAY = 5500;
const TYPED_TEXT_DELAY = 20;

/**
 * This function checks if a client has prefers reduced motion active
 * @returns boolean
 */
export function queryReducedMotion() {
  return typeof window !== 'undefined'
    && typeof window.matchMedia === 'function'
    && window.matchMedia('(prefers-reduced-motion)').matches;
}

const useTypedText = (placeholderTexts, pauseTextAnimation) => {
  const [typedTextIndex, setTypedTextIndex] = useState(0);
  const [placeholderTextIndex, setPlaceholderTextIndex] = useState(0);
  const typedTextPhrase = placeholderTexts[placeholderTextIndex % placeholderTexts.length];
  const lastCharacter = typedTextIndex >= typedTextPhrase.length;

  useEffect(() => {
    let timeoutHandle = null;
    const reduceMotion = queryReducedMotion();

    if (!reduceMotion && !pauseTextAnimation) {
      timeoutHandle = setTimeout(() => {
        if (lastCharacter) setPlaceholderTextIndex(v => v + 1);

        setTypedTextIndex(v => (lastCharacter ? 0 : v + 1));
      }, lastCharacter ? ANIMATION_DELAY : TYPED_TEXT_DELAY);
    }
    if (reduceMotion) {
      setTypedTextIndex(typedTextPhrase.length);
    }
    return () => isNumber(timeoutHandle) && clearTimeout(timeoutHandle);
  }, [typedTextIndex, pauseTextAnimation]);

  return typedTextPhrase.slice(0, typedTextIndex);
};

const EngagingSearchPlaceholder = ({
  placeholderTexts,
  pauseTextAnimation,
  hidden,
  defaultPlaceholderText,
}) => {
  const typedText = useTypedText([defaultPlaceholderText, ...placeholderTexts], pauseTextAnimation);

  const containerClasses = cnames(
    styles.scrollingTextContainer,
    { [styles.scrollingTextHidden]: hidden },
  );

  return (
    <div className={containerClasses} aria-hidden="true" data-testid="engaging-search-placeholder">
      {!pauseTextAnimation && (
        <p data-testid="typed-text" className={cnames(styles.scrollingTextItem, styles.typedText)}>
          {typedText}
        </p>
      )}
    </div>
  );
};

EngagingSearchPlaceholder.propTypes = {
  placeholderTexts: PropTypes.arrayOf(PropTypes.string).isRequired,
  pauseTextAnimation: PropTypes.bool.isRequired,
  hidden: PropTypes.bool,
  defaultPlaceholderText: PropTypes.string.isRequired,
};

EngagingSearchPlaceholder.defaultProps = {
  hidden: false,
};

export default EngagingSearchPlaceholder;
