import React from 'react';
import { parse as generateDocs, resolver } from 'react-docgen';
import * as constants from '../constants';
import MediaCard from '.';
import StarIcon from '../Icons/Star';
import CartAddIcon from '../Icons/CartAdd';
import StarRating from '../StarRating';
import Tooltip from '../Tooltip';
import Box from '../Box';
import Button from '../Button';
import Skeleton from '../Skeleton';
import Text from '../Text';

// eslint-disable-next-line
import MediaCardSrc from '!raw-loader!./';

const docs = generateDocs(MediaCardSrc, resolver.findAllExportedComponentDefinitions);

const basic = {
  name: 'Basic',
  description: 'It can appear as a card or be transparent.',
  overflowVisual: true,
  render: () => (
    <Box display="flex" flexWrap="wrap">
      <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
        <MediaCard
          href="#"
          title="Title"
          caption="Transparent"
          transparent
          imageSrc="https://bit.ly/2OEC442"
        />
      </Box>
      <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
        <MediaCard
          href="#"
          title="Title"
          caption="Default"
          imageSrc="https://bit.ly/2Uk9Jpg"
        />
      </Box>
    </Box>
  ),
  code: `
<Box display="flex" flexWrap="wrap">
  <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
    <MediaCard
      href="#"
      title="Title"
      caption="Transparent"
      transparent
      imageSrc="https://bit.ly/2OEC442"
    />
  </Box>
  <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
    <MediaCard
      href="#"
      title="Title"
      caption="Default"
      imageSrc="https://bit.ly/2Uk9Jpg"
    />
  </Box>
</Box>
  `,
};

const imageRatio = {
  name: 'Image ratio',
  description: 'You can force the image to be square or portrait.',
  overflowVisual: true,
  render: () => (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexWrap="wrap">
        <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
          <MediaCard
            href="#"
            title="Portrait"
            imageSrc="https://bit.ly/2Uk9Jpg"
            imageRatio={constants.RATIO_PORTRAIT}
            transparent
          />
        </Box>
        <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
          <MediaCard
            href="#"
            title="Square"
            caption="Test"
            imageSrc="https://bit.ly/2Uk9Jpg"
            imageRatio={constants.RATIO_SQUARE}
            transparent
          />
        </Box>
        <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
          <MediaCard
            href="#"
            title="Square"
            caption="Test"
            imageSrc="https://bit.ly/2Uk9Jpg"
            imageRatio={{ type: constants.RATIO_SQUARE, mode: constants.RATIO_CONTAIN }}
            transparent
          />
        </Box>
      </Box>
    </Box>
  ),
  code: `
<Box display="flex" flexDirection="column">
  <Box display="flex" flexWrap="wrap">
    <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
      <MediaCard
        href="#"
        title="Portrait"
        imageSrc="https://bit.ly/2Uk9Jpg"
        imageRatio={constants.RATIO_PORTRAIT}
        transparent
      />
    </Box>
    <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
      <MediaCard
        href="#"
        title="Square"
        caption="Test"
        imageRatio={constants.RATIO_SQUARE}
        transparent
      />
    </Box>
  </Box>
</Box>
  `,
};

const actions = {
  name: 'Actions',
  description: `
  You can provide a single primary action in the top right hand corner and multiple additional actions in a contextual menu.\n\nWe recommend that you read our [Usage Guidelines](/components/media-card/usage) and [API Docs](/components/media-card/api-docs#secondary-actions) for these features.
  `,
  overflowVisual: true,
  config: responsive => ({
    initialValues: {
      profile: responsive.desktop ? constants.DESKTOP : constants.MOBILE,
    },
    fields: [
      {
        label: 'Profile',
        name: 'profile',
        type: 'button-toggle',
        options: [
          { value: constants.MOBILE, label: 'Mobile' },
          { value: constants.DESKTOP, label: 'Desktop' },
        ],
      },
    ],
  }),
  render: (_, values) => (
    <Box display="flex" flexWrap="wrap">
      <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
        <MediaCard
          profile={values.profile}
          href="#"
          title="Action"
          caption="Primary"
          imageSrc="https://bit.ly/2Uk9Jpg"
          transparent
          primaryActionSlot={(
            <Button
              elevation={constants.ELEVATION_MEDIUM}
              circle
              size={constants.SMALL}
              iconAfter={<StarIcon size={constants.SMALL} />}
            />
          )}
        />
      </Box>
      <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
        <MediaCard
          profile={values.profile}
          href="#"
          title="Action"
          caption="Secondary"
          imageSrc="https://bit.ly/2Uk9Jpg"
          transparent
          secondaryActions={[
            { render: 'T-Shirts', href: 'https://www.redbubble.com/shop/t-shirts', target: '_blank' },
            // eslint-disable-next-line no-console
            { render: 'Hoodies', onClick: () => console.log('Clicked hoodies') },
            { render: 'Skirts', href: 'https://www.redbubble.com/shop/t-shirts' },
            { render: 'Kids', href: 'https://www.redbubble.com/shop/t-shirts' },
          ]}
        />
      </Box>
      <Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
        <MediaCard
          profile={values.profile}
          href="#"
          title="Action"
          caption="Both"
          imageSrc="https://bit.ly/2Uk9Jpg"
          transparent
          primaryActionSlot={(
            <Button
              elevation={constants.ELEVATION_MEDIUM}
              circle
              size={constants.SMALL}
              iconAfter={<StarIcon size={constants.SMALL} />}
            />
          )}
          secondaryActions={[
            { render: 'T-Shirts', href: 'https://www.redbubble.com/shop/t-shirts' },
            // eslint-disable-next-line no-console
            { render: 'Hoodies', onClick: () => console.log('Clicked hoodies') },
            { render: 'Skirts', href: 'https://www.redbubble.com/shop/t-shirts' },
            { render: 'Kids', href: 'https://www.redbubble.com/shop/t-shirts' },
          ]}
        />
      </Box>
    </Box>
  ),
  code: (_, values) => `
<Box padding="m" flex="1 1 0" style={{ maxWidth: '200px' }}>
  <MediaCard
    profile={${values.profile === constants.DESKTOP ? 'constants.DESKTOP' : 'constants.MOBILE'}}
    href="#"
    title="Action"
    caption="Both"
    imageSrc="https://bit.ly/2Uk9Jpg"
    transparent
    primaryActionSlot={(
      <Button
        elevation={constants.ELEVATION_MEDIUM}
        circle
        size={constants.SMALL}
        iconAfter={<StarIcon size={constants.SMALL} />}
      />
    )}
    secondaryActions={[
      { render: 'T-Shirts', href: 'https://www.redbubble.com/shop/t-shirts' },
      { render: 'Hoodies', onClick: () => console.log('Clicked hoodies') },
      { render: 'Skirts', href: 'https://www.redbubble.com/shop/t-shirts' },
      { render: 'Kids', href: 'https://www.redbubble.com/shop/t-shirts' },
    ]}
  />
</Box>
  `,
};

const loading = {
  name: 'Loading placeholders',
  description: 'The `MediaCard` can be rendered in a `loading` state which works well for skeleton or placeholder layouts while content is loading.',
  overflowVisual: true,
  render: () => (
    <Box display="flex" flexWrap="wrap">
      <Box padding="m" flex="1 1 0" style={{ maxWidth: '240px' }}>
        <MediaCard
          href="#"
          title="Product name"
          loading
          caption="Artist"
          imageRatio={constants.RATIO_PORTRAIT}
          transparent
        />
      </Box>

      <Box padding="m">
        <MediaCard
          href="#"
          title="Product name"
          transparent
          loading
          caption="Artist"
          imageRender={() => (
            <Skeleton roundedCorners={false} width="200px" height="200px" />
          )}
        />
      </Box>
    </Box>
  ),
  code: `
<Box display="flex" flexWrap="wrap">
  <Box padding="m" flex="1 1 0" style={{ maxWidth: '240px' }}>
    <MediaCard
      href="#"
      title="Product name"
      loading
      caption="Artist"
      imageRatio={constants.RATIO_PORTRAIT}
      imageSrc="https://bit.ly/2OEC442"
      transparent
    >
      <StarRating rating={4} />
    </MediaCard>
  </Box>

  <Box padding="m">
    <MediaCard
      href="#"
      title="Product name"
      transparent
      loading
      caption="Artist"
      transparent
      imageRender={() => (
        <Skeleton roundedCorners={false} width="200px" height="200px" />
      )}
    />
  </Box>
</Box>
  `,
};

const customContent = {
  name: 'Custom content',
  description: 'You can add your own content at the bottom of the card **or** [customise the image region](/components/media-card/api-docs#customizing-the-image-region).\n\n This can be handy for for things like fixed ratioplaceholders, price, reviews, or custom actions like add to cart.',
  overflowVisual: true,
  render: () => (
    <Box display="flex" flexWrap="wrap">
      <Box padding="m" flex="1 1 0" style={{ maxWidth: '240px' }}>
        <MediaCard
          href="#"
          title="Product name"
          caption="Artist"
          imageSrc="https://bit.ly/2OEC442"
          transparent
        >
          <Text type="display5" display="block">$23.99</Text>
          <StarRating rating={4} />
        </MediaCard>
      </Box>
      <Box padding="m" flex="1 1 0" style={{ maxWidth: '240px' }}>
        <MediaCard
          href="#"
          title="Product name"
          caption="Artist"
          imageSrc="https://ih1.redbubble.net/image.914354835.4882/ur,water_bottle_metal_lid_on,square,600x600.2u1.jpg"
          transparent
        >
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Text type="display5" display="block">$23.99</Text>
              <Box
                paddingTop="xs"
                style={{ color: 'var(--ds-color-information-500)' }}
              >
                <Text type="caption" display="block">$1.80 when buying 10 or more!</Text>
              </Box>
            </Box>
            <Box paddingLeft="m">
              <Tooltip
                trigger={triggerProps => (
                  <Button
                    intent={constants.PRIMARY}
                    circle
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      // eslint-disable-next-line no-alert
                      alert('Added to cart ;)');
                    }}
                    iconAfter={<CartAddIcon />}
                    {...triggerProps}
                  />
                )}
                accessibleTitle="Add to cart"
              >
                Add to cart
              </Tooltip>
            </Box>
          </Box>
        </MediaCard>
      </Box>
      <Box padding="m" flex="1 1 0" style={{ maxWidth: '240px' }}>
        <MediaCard
          href="#"
          title="Artwork"
          caption="Fixed ratio"
          transparent
          imageRender={() => (
            <Box style={{ paddingBottom: '100%', background: 'var(--ds-color-state-loading-background)' }} />
          )}
        />
      </Box>
    </Box>
  ),
  code: `
<Box display="flex" flexWrap="wrap">
  <Box padding="m" flex="1 1 0" style={{ maxWidth: '240px' }}>
    <MediaCard
      href="#"
      title="Product name"
      caption="Artist"
      imageSrc="https://bit.ly/2OEC442"
      transparent
    >
      <Text type="display5">$23.99</Text>
      <StarRating rating={4} />
    </MediaCard>
  </Box>
  <Box padding="m" flex="1 1 0" style={{ maxWidth: '240px' }}>
    <MediaCard
      href="#"
      title="Product name"
      caption="Artist"
      imageSrc="https://bit.ly/2OEC442"
      transparent
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Text type="display5" display="block">$23.99</Text>
          <Box
            paddingTop="xxs"
            style={{ color: 'var(--ds-color-information-500)' }}
          >
            <Text type="caption" display="block">$1.80 when buying 10 or more!</Text>
          </Box>
        </Box>
        <Box paddingLeft="m">
          <Button
            intent={constants.PRIMARY}
            circle
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              // eslint-disable-next-line no-alert
              alert('Added to cart ;)');
            }}
            iconAfter={<CartAddIcon />}
          />
        </Box>
      </Box>
    </MediaCard>
  </Box>
  <Box padding="m" flex="1 1 0" style={{ maxWidth: '240px' }}>
    <MediaCard
      href="#"
      title="Artwork"
      caption="Fixed ratio"
      transparent
      imageRender={() => (
        <Box style={{ paddingBottom: '100%', background: 'var(--ds-color-state-loading-background)' }} />
      )}
    />
  </Box>
</Box>
  `,
};

const Demo = {
  title: MediaCard.displayName,
  description: 'Our MediaCard is used to encourage our users to take one, or multiple actions related to a product or an artwork. They are commonly used in grids and carousels.',
  slug: 'media-card',
  examples: [
    basic,
    actions,
    customContent,
    imageRatio,
    loading,
  ],
  docGen: docs,
  notes: `
### Customizing the image region

The \`imageRender\` prop allows you to render custom content in the image region of the \`MediaCard\`.

We **do not recommend** that you use this feature to render complex interactions, videos or other embedded media. Instead, use it to augment an otherwise simple image experience.

Examples include:

- custom fixed size loading placeholders
- interactive mature content guards
- zoom on hover functionality

When you specify \`imageRender\` you are taking on the responsibility of rendering the image and as such
it's up to you to specify the required props on the \`Image\` component including:

- \`src\`
- \`alt\`
- \`loadOnVisible\`
- \`ratio\`
- etc

Note that \`imageRender\` will provide you with \`imageProps\`. Typically these should be spread onto the image that you render to maintain the same function as a regular \`MediaCard\`.

**Example:**

\`\`\`
<MediaCard
  title="Title"
  caption="Caption"
  imageRender={({ imageProps }) => (
    <ZoomOnHover>
      <Image
        {...imageProps}
        src="path/to/img"
        alt="an image"
        ratio={constants.RATIO_PORTRAIT}
      />
    </ZoomOnHover>
  )}
/>
\`\`\`

### Custom element tags

You can choose to wrap the \`MediaCard\` in a custom element. This will for example allow you to do client side routing:

\`\`\`
<MediaCard
  element={Link}
  to="/explore"
  imageSrc="https://bit.ly/2Uk9Jpg"
  title="Title"
  caption="Caption"
/>
\`\`\`

### Secondary Actions

You can pass a collection of items to be rendered as secondary actions in a contextual menu:

\`\`\`
<MediaCard
  {...otherProps}
  secondaryActions={[
    { render: 'T-Shirts', href: 'https://www.redbubble.com/shop/t-shirts' },
    { render: 'Hoodies', href: 'https://www.redbubble.com/shop/t-shirts' },
    { render: 'Skirts', href: 'https://www.redbubble.com/shop/t-shirts' },
    { render: 'Kids', href: 'https://www.redbubble.com/shop/t-shirts' },
  ]}
/>
\`\`\`

The signature of each item should match the props of a [MenuItem](/components/Menu/api-docs).

The \`render\` property will be used as the \`children\` of the \`MenuItem\`.

Other \`MenuItem\` props are therefore also supported including:

- \`iconBefore\`
- \`iconAfter\`
- \`onClick\`
- \`href\`


  `,
};


export default Demo;
